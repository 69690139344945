import aromaMassage from '../images/services/aromaMassage.png';
import healingMassage from '../images/services/healingMassage.png';
import isisLotusHealing from '../images/services/isisLotusHealing.png';
import meditation from '../images/services/meditation.png';
import raindropTechnique from '../images/services/raindropTechnique.png';
import reiki from '../images/services/reiki.png';
import remedialMassage from '../images/services/remedialMassage.png';

const Service = ({ title, image, children }) => {
  return (
    <div className="col-md-4 col-sm-6">
      <img className="subsubimage" src={image} />
      <div className="subsubcontent">
        <h4 className="pageSubSubheading">{title}</h4>
        {children}
      </div>
    </div>
  );
};

const Services = () => {
  return (
    <section id="services">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading">Services</h2>
            <h3 className="section-subheading text-muted">All centred around you well-being</h3>
          </div>
        </div>
        <div className="row subheadingRow">
          <div className="col-md-12">
            <h3 className="pageSubheading">Massage</h3>
          </div>
        </div>
        <div className="row equal subsubheadingRow">
          <Service title="Relaxation Massage" image={aromaMassage}>
            <p>Combination of gentle massage and essential oils to relax the mind and body</p>
            <ul>
              <li>30 minutes: $65</li>
              <li>60 minutes: $120</li>
              <li>Additional aromatherapy oils: $10</li>
            </ul>
          </Service>
          <Service title="Remedial Massage" image={remedialMassage}>
            <p>Deep tissue massage, to relieve problem areas</p>
            <ul>
              <li>30 minutes: $70</li>
              <li>60 minutes: $130</li>
              <li>90 minutes: $170</li>
              <li>Cupping: extra $20</li>
              <li>Ortho-Bionomy: extra $10</li>
            </ul>
          </Service>
          <Service title="Massage and Healing" image={healingMassage}>
            <p>These session makes use of all of the following modalities listed below, I will be guided to use what the individual needs to bring balance and a sense of peace. A session will realign the body&apos;s energy chakras and remove any blocks from the body leaving you feeling lighter in the mind and body.</p>
            <p>These modalities will leave you feeling lighter</p>
            <ul>
              <li>60 minutes: $150</li>
              <li>90 minutes: $210</li>
              <li>120 minutes: $280</li>
              <li>Additional: Angel card reading $50</li>
            </ul>
          </Service>
          <Service title="Ortho-Bionomy">
            <p>
              A gentle pain free modality that focuses on you and your unique needs. Effective body work that employs subtle, yet deliberate movements that reposition your body to naturally release your stress and pain patterns.
              If you&apos;re experiencing pain and want to avoid painful manipulations and forceful pressures, then you may like to consider Ortho-Bionomy.
            </p>
            <ul>
              <li>60 minutes: $120 without massage</li>
              <li>$20 additional with massage</li>
            </ul>
          </Service>
        </div>
        <div className="row subheadingRow">
          <div className="col-md-12">
            <h3 className="pageSubheading">Healing</h3>
          </div>
        </div>
        <div className="row equal subsubheadingRow">
          <Service title="Isis Lotus Healing" image={isisLotusHealing}>
            <p>A 3 session healing for optimum results. Invokes the power of ancient egyptian rituals and beliefs</p>
          </Service>
          <Service title="Reiki" image={reiki}>
            <p>A spiritual japanese practice restoring and supporting the ki life force. Healing modalities are combined to suit your needs</p>
            <p>An absent healing can be arranged via video conference in the comfort of your own home.</p>
            <ul>
              <li>60 minutes: $150</li>
              <li>90 minutes: $200</li>
            </ul>
          </Service>
          <Service title="Raindrop technique" image={raindropTechnique}>
            <p>Inspired by the Native America legend, the technique is one of Young living&apos;s most popular essential oils collections and has been used for years as a highly effective method for balancing and rejuvenating.</p>
            <ul>
              <li>60 minutes: $120</li>
            </ul>
          </Service>
          <Service title="Spiritual - counselling" image={meditation}>
            <p>A great follow on after a healing where the individual wants to make a change to improve their state of being. Changing some of the behaviours and help to respond appropriately to potentially stressful situations.</p>
            <p>&quot;Thoughts and beliefs largely determine the way we feel, as everything you say is a thought expressed.&quot;</p>
            <ul>
              <li>$150 per session for 1 hour</li>
            </ul>
          </Service>
          <Service title="Mind-Break Through Session" image={meditation}>
            <p>A 6-8 hours powerful mind process that brings change and awareness to the patterns, limiting beliefs, limiting decisions, values, strategies and conflict in the client’s life. A letting goes of all negative emotions especially Anger, sadness, fear, shame, guilt and conflict so to loosen the grip on the problem that is keeping the client stuck and limited.</p>
            <p>I believe that every single one of us, is called to greatness, and every experience that we have is a part of the journey of arriving at our very own full expression of that greatness.</p>
            <p>In every circumstance we face, we can choose to either shrink back, or to use what resources and opportunities we have to hold true to our values, move toward our dreams, and to act and think like the kind of person we choose and desire to be.</p>
            <ul>
              <li>$250 per session</li>
              <li>Skype coaching available if living in a different state or country</li>
            </ul>
          </Service>
        </div>
        <div className="row subheadingRow">
          <div className="col-md-12">
            <h3 className="pageSubheading">Additional</h3>
          </div>
        </div>
        <div className="row subsubheadingRow additionalServices">
          <Service title="Crystal cleansing" />
          <Service title="Tarot card reading" />
          <Service title="Reflexology" />
        </div>
      </div>
    </section>
  );
};


export default Services;
