import GraceMech from '../images/gracemech.jpg';

const Team = () => {
  return (
    <section id="about" className="bg-light-gray">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading">About Me</h2>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="justify-content-md-center">
            <div className="person">
              <img src={GraceMech} className="img-fluid graceImg" alt="Grace Mech" />
              <h4>Grace Mech</h4>
              <h3 className="section-subheading text-muted">Massage Therapist</h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-lg-10 col-lg-offset-1 text-center">
            <p
              className="large text-muted"
            >I have been involved with teaching since my early childhood seeking the spiritual mysteries of the world.</p>

            <p
              className="large text-muted"
            >My journey began after the death of my little brother. I was curious, in my grief, to find out who was God. I become a secondary school teacher in Art and religion and since then I diversified into other spiritual perspectives, gaining qualification in massage, Isis lotus healing, Reiki, crystals and colours, massage, cupping, Ortho Bionomy, aromatherapy.</p>
          </div>
        </div>
      </div>
    </section>
  );

};

export default Team;
