import Axios from 'axios';
class Api {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  sendEmail(data) {
    if (this.endpoint.length <= 0) {
      throw new Error('invalid endpoint');
    }
    return Axios({
      method: 'POST',
      url: `${this.endpoint}/booking`,
      headers: {
        'Content-Type': 'application/json'
      },
      data
    }).then((result) => result.data);
  }
}

export default Api;
