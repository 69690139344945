import './App.scss';
import Footer from './components/Footer';
import Home from './pages/Home';

function App() {
  return (
    <div id="site">
      <section className="body">
        <Home />
      </section>
      <Footer />
    </div>
  );
}

export default App;
