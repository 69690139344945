import Facebook from '../images/facebook.png';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-8 copywrite">
            <span className="copyright">Copyright &copy; Inner Serenity ABN: 45 593 131 637| Created by <a href="http://www.julianpittas.com.au">Julian Pittas</a></span>
          </div>
          <div className="col-md-4">
            <ul className="list-inline social-buttons">
              <li><a target="_blank" href="https://www.facebook.com/pages/Inner-Serenity-Massage-and-Healing/209283215754693" rel="noreferrer"><img src={Facebook} className="img-fluid" alt="Facebook" /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
