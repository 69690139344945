const Testimonial = ({ name, children }) => (
  <div className="col-md-4 col-sm-12">
    <div className="testimonial">
      <p>
        {children}
      </p>
      <div className="name">- {name}</div>
    </div>
  </div>
);

const Testimonials = () => {
  return (
    <section id="testimonials">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading">Testimonials</h2>
          </div>
        </div>
        <div className="row subsubheadingRow">
          <Testimonial name={'Rich'}>
            What would I do without Grace?!
            For many years I’ve been coming to see her
            to massage my body, mind and soul! You
            are a unique gem and I’m very lucky to have
            you in my life! Thank you
          </Testimonial>
          <Testimonial name={'Velinka'}>
            Amazing! Simply amazing….!
            You allowed me to go to another place,
            away from the stresses of every day life, a
            place of beauty, peace and ‘ME’ time.
            You not only relax the body but the mind
            with your insightful chats. I thank you as
            you always go above and beyond. Much
            Love
          </Testimonial>
          <Testimonial name={'Ally'}>
            Grace is a very special person. Coming
            to see her regularly for the last 6.5 years for
            healing and massage was one of the best
            decisions for my personal wellbeing that I
            would have made.
          </Testimonial>
          <Testimonial name={'Maroula'}>
            You have given me such a
            wonderful, heartfelt experience. Thank you.
            More than I ever expected.
          </Testimonial>
          <Testimonial name={'Eva'}>
            Beautiful massage. Very relaxing. I am
            a tough one to massage but this was
            fantastic. I feel amazing and floating.
          </Testimonial>
          <Testimonial name={'Julia'}>
            Really helpful in more than just a
            massage, I feel the whole person and spirit
            is being healed
          </Testimonial>
        </div>
      </div>
    </section >
  );
};

export default Testimonials;
