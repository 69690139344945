import { Link } from 'react-scroll';
import { useState, useEffect } from 'react';
import Logo from '../images/logos/innerserenity.png';

const SmoothScrollLink = ({ scrollToID, text }) => <Link
  activeClass="active"
  className="page-scroll nav-link"
  to={scrollToID}
  spy={true}
  smooth={true}
  duration={100}
  offset={-55}
>
  {text}
</Link>;

const Navigation = () => {

  const [pos, setPos] = useState('top');

  useEffect(() => {
    document.addEventListener('scroll', () => {
      let scrolled = document.scrollingElement.scrollTop;
      setPos(scrolled);
    });
  }, []);

  return (
    <nav className={`navbar navbar-default fixed-top navbar-expand-lg ${pos > 100 ? 'scrolled' : ''}`}>
      <div className="container">

        <a className="navbar-brand" href="#page-top"><img src={Logo} id="logo" alt="Inner Serenity logo" /></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse nav" id="navbarNav">
          <ul className="navbar-nav d-flex flex-grow-1 justify-content-end">
            <li className="nav-item">
              <SmoothScrollLink scrollToID="services" text="Services" />
            </li>
            <li className="nav-item">
              <SmoothScrollLink scrollToID="about" text="About" />
            </li>
            <li className="nav-item">
              <SmoothScrollLink scrollToID="testimonials" text="Testimonials" />
            </li>
            <li className="nav-item">
              <SmoothScrollLink scrollToID="booking" text="Book" />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
