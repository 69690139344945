import Contact from '../components/Contact';
import Navigation from '../components/Navigation';
import Services from '../components/Services';
import Team from '../components/Team';
import Testimonials from '../components/Testimonials';


const Home = () => {
  return (
    <div>
      {/* Header */}
      <Navigation />
      <header>
        <div className="container">
          <div className="intro-text">
            <div className="intro-lead-in">Welcome To Inner Serenity</div>
            <div className="intro-heading">
              It&apos;s Time To
              <div className="relaxText">Relax</div>
            </div>
            <a
              href="#services"
              className="page-scroll btn btn-xl"
              v-smooth-scroll="{ duration: 1000}"
            >Show me how</a>
          </div>
        </div>
      </header>
      <Services />
      <Team />
      <Testimonials />
      <Contact />

    </div >
  );
};

export default Home;
