
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import Api from '../utils/Api';
import config from '../aws-exports';

const ContactForm = () => {
  const API = new Api(config.aws_cloud_logic_custom[0].endpoint);
  // const API = {
  //   post: (data) => {
  //     console.log(data);
  //     return Promise.resolve(
  //       {
  //         data: { success: true }
  //       }
  //     );
  //   }
  // };

  const { register, reset, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data => {
    API.sendEmail(data).then((result) => {
      if (result && result && result.success) {
        Swal.fire(
          'Sent',
          'Your message has been successfully sent, please allow 3-5 business days for a reply',
          'success'
        );
        reset({
          name: '',
          phone: '',
          email: '',
          message: ''
        });
      } else {
        console.log(result);
        Swal.fire(
          'Error',
          'Unable to send your message, please try again shortly or call Grace on +61 438 334 082',
          'error'
        );
      }

    }).catch((error) => {
      console.error(error);
      Swal.fire(
        'Error',
        'Unable to send your message, please try again shortly or call Grace on +61 438 334 082',
        'error'
      );
    });
  };

  return (
    <div id="candidatesForm">
      <form className="candidates-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">

            <div className="form-group">
              <input type="text" placeholder="Your Name *" {...register('name', { required: true, })} className="form-control" />
              <span className="text-danger">{errors.name?.type === 'required' && 'Name is required'}</span>
            </div>

            <div className="form-group">
              <input type="phone" placeholder="Your Phone *" {...register('phone', { required: true, minLength: 10, maxLength: 12, pattern: /^((\+614|614)[0-9]{8})|((04)[0-9]{8})$/ })} className="form-control" />
              <span className="text-danger">
                {errors.phone?.type === 'required' && 'Phone is required'}
                {(errors.phone?.type === 'minLength' || errors.phone?.type === 'maxLength' || errors.phone?.type === 'pattern') && 'Phone number is invalid'}
              </span>
            </div>

            <div className="form-group">
              <input type="email" placeholder="Your Email *" {...register('email', { required: true, })} className="form-control" />
              <span className="text-danger">
                {errors.email?.type === 'required' && 'Email is required'}
              </span>
            </div>

          </div>
          <div className="col-md-6">

            <div className="form-group">
              <textarea placeholder="Your Message *" {...register('message', { required: true, })} data-vv-delay="500" className="form-control" >
              </textarea>
              <span className="text-danger">{errors.message?.type === 'required' && 'Message is required'}</span>
            </div>

          </div>

          <div className="mb-12 text-center">
            <button type="submit" id="submitButton" data-style="expand-left" className="btn btn-xl" >
              <span>Send</span>
            </button>
          </div>

        </div >
      </form>
    </div >
  );
};

const Booking = () => {
  return (
    < section id="booking" >
      <div id="bookingOverlay">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">Make a booking</h2>
              <h3 className="section-subheading text-muted">
                Fill in the form below to request a booking
                <br />Alternatively, you can call <a href="tel:+61438334082">+61 438 334 082</a> to speak with Grace personally
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section >
  );
};

export default Booking;
